<script setup lang="ts">
defineProps<{
  help: string;
}>();

const userStore = useUserStore();

function redirectWhenCreateTeam(value: any) {
  if (value && value.create_team) {
    useSystemStore().formModalOpen = false;
    useRouter().push(`/dashboard/create-team`);
  }
}

const ownerOptions = computed(() => {
  return [
    { label: "My Forms", value: { user_id: userStore.user?.id } },
    ...userStore.teams.map((team) => ({
      label: `${team.name} Forms`,
      value: { team_id: team.id },
      __team: team,
    })),
    { label: "+ Create a team", value: { create_team: true } },
  ];
});
</script>

<template>
  <FormKit
    type="dropdown"
    name="owner"
    outer-class="!mb-0"
    inner-class="!border-slate-300 shadow-none"
    help-class="!text-slate-400 mt-0.5 ml-0.5 text-[11px]"
    selection-class="!text-slate-500 !text-sm"
    wrapper-class="!mb-0"
    :help="help"
    popover
    :options="ownerOptions"
    :value="{ user_id: userStore.user?.id }"
    @input="redirectWhenCreateTeam"
  >
    <template #option="{ classes, option }">
      <div
        :class="`
          ${classes.option}
          flex
          items-center
          gap-2
        `"
      >
        <Avatar v-if="option.__original.user_id" class="!w-5 !h-5" />
        <Avatar
          v-else-if="option.__team"
          :for="option.__team"
          class="!w-5 !h-5"
        />
        <span class="text-slate-700">{{ option.label }}</span>
      </div>
    </template>
  </FormKit>
</template>
