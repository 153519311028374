<script setup lang="ts">
import { AvatarImage, AvatarRoot, AvatarFallback } from "radix-vue";
const props = defineProps<{
  for?: { avatar?: string; name: string };
}>();

const { user: userSession } = useUserSession();

const user = computed(() => props.for ?? userSession.value);

const avatarSrc = computed(() => {
  const url = user.value?.avatar;
  if (url && /^https?:\/\//.test(url)) {
    return `/avatar`;
  } else if (url) {
    return `https://${useRuntimeConfig().public.awsBucket}/${url}`;
  }
  return null;
});
</script>

<template>
  <AvatarRoot
    class="bg-slate-500 text-white text-3xl inline-flex h-[1.25em] w-[1.25em] select-none items-center justify-center overflow-hidden rounded-full align-middle"
    v-if="user"
  >
    <AvatarImage
      class="h-full w-full rounded-full object-cover"
      :src="avatarSrc"
      v-if="avatarSrc"
    />
    <AvatarFallback :delay-ms="600" class="text-sm">
      {{ getInitials(user.name ?? "?") }}
    </AvatarFallback>
  </AvatarRoot>
</template>

<style></style>
