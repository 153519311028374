export function getInitials(name: string): string {
  // Remove leading/trailing whitespace and split the name into parts
  const nameParts = name.trim().split(/\s+/)

  if (nameParts.length === 0) {
    return ''
  }

  if (nameParts.length === 1) {
    // If there's only one part, return the first letter
    return nameParts[0].charAt(0).toUpperCase()
  }

  // Get the first letter of the first part
  const firstInitial = nameParts[0].charAt(0).toUpperCase()

  // Get the first letter of the last part
  const lastInitial = nameParts[nameParts.length - 1].charAt(0).toUpperCase()

  return firstInitial + lastInitial
}
